import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from 'components/ReflexBox';

import Page from 'components/Page';
import CenterContent from 'components/CenterContent';
import { H1, H2, H3, H4, Text, TextLight, Label } from 'components/Typo';
import Button from 'components/Button';
import SectionIntro from 'components/SectionIntro';
import ClientGrid from 'components/ClientGrid';
import ProjectIntro from 'components/ProjectIntro';
import CodeBlock from 'components/CodeBlock';
import Icon from 'components/Icon';
import Contact from 'components/Contact';
import ServiceGrid from 'components/ServiceGrid';
import ServiceInformation from 'components/ServiceInformation';
import TechnologyStack from 'components/TechnologyStack';
import CollaborationButton from 'components/CollaborationButton';

import { baseColors, darkColors, lightColors } from 'themes';

const Section = styled(CenterContent)`
  margin: 2em auto 4em auto;
`;

const ColorSwatch = styled.div`
  background: ${(p) => p.color};
  border-radius: 2px;
  position: relative;
  height: 30px;
  margin: 15px 0 15px 0;

  &:after {
    content: '${(p) => p.name}';
    position: absolute;
    top: -14px;
    font-size: 12px;
  }
`;

const StyleguideSectionTitle = styled(Text)`
  font-weight: 700;
  font-size: 1rem;
  margin: 3rem 0 1rem 0;
  background: ${(p) => p.theme.colors[p.color]};
`;

const colorCodes = [
  { prefix: 'base', colors: baseColors },
  { prefix: 'light', colors: lightColors },
  { prefix: 'dark', colors: darkColors },
].map((c) => ({
  ...c,
  colors: Object.keys(c.colors).map((key) => ({ key, color: c.colors[key] })),
}));

const iconProps = [
  { name: 'opensource', colorizeStroke: false },
  { name: 'datavis', colorizeStroke: false },
  { name: 'frontend', colorizeStroke: false },
  { name: 'backend', colorizeStroke: false },
];

const iconPropsSmall = [
  { name: 'arrow_right_circle', colorizeStroke: true },
  { name: 'diamond', colorizeStroke: true },
  { name: 'graph_bar', colorizeStroke: true },
  { name: 'info_circle', colorizeStroke: true },
  { name: 'lightning', colorizeStroke: true },
  { name: 'link', colorizeStroke: true },
  { name: 'mail', colorizeStroke: true },
];

const metaTags = {
  title: 'webkid - styleguide',
  author: 'Webkid',
  twitterUsername: '@wbkd',
  description: 'webkid styleguide',
  siteUrl: 'https://webkid.io/about/',
  robots: 'noindex,nofollow',
};

const Styleguide = () => {
  const [theme, setTheme] = useState('dark');

  const toggleTheme = () => {
    setTheme((t) => (t === 'dark' ? 'light' : 'dark'));
  };

  return (
    <Page theme={theme} metaTags={metaTags}>
      <Section>
        <StyleguideSectionTitle>Typography</StyleguideSectionTitle>
        <H1>h1 Welcome to our Styleguide!</H1>
        <H2>h2 Welcome to our Styleguide!</H2>
        <H3>h3 Welcome to our Styleguide!</H3>
        <H4>h4 Welcome to our Styleguide!</H4>
        <Text>
          Text: Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
          diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
          erat, sed diam voluptua
        </Text>
        <TextLight>
          TextLight: Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
          sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
          aliquyam erat, sed diam voluptua
        </TextLight>
        <Label>Label</Label>
      </Section>

      <Section>
        <StyleguideSectionTitle>Buttons</StyleguideSectionTitle>
        <Button onClick={toggleTheme}>Toggle Theme</Button>
        <Button onClick={() => console.log('huhu')} icon="diamond">
          Icon Button
        </Button>
      </Section>

      <Section>
        <StyleguideSectionTitle>Collaboration Button</StyleguideSectionTitle>
        <CollaborationButton />
      </Section>

      <Section>
        <StyleguideSectionTitle>Colors</StyleguideSectionTitle>

        {colorCodes.map((cc) => (
          <Fragment key={cc.prefix}>
            {cc.prefix && <Text>{cc.prefix}</Text>}
            <Flex marginX="-5px" flexWrap="wrap">
              {cc.colors.map((c) => (
                <Box width="25%" padding="5px" key={c.key}>
                  <ColorSwatch name={c.key} color={c.color} />
                </Box>
              ))}
            </Flex>
          </Fragment>
        ))}
      </Section>

      <Section>
        <StyleguideSectionTitle>Icons</StyleguideSectionTitle>
        <Flex marginX="-5px" flexWrap="wrap">
          {iconProps.map((props) => (
            <Box width="20%" padding="5px" key={props.name}>
              <Label>{props.name}</Label>
              <Icon name={props.name} colorizeStroke={props.colorizeStroke} />
            </Box>
          ))}
        </Flex>
        <Flex marginX="-5px" flexWrap="wrap">
          {iconPropsSmall.map((props) => (
            <Box width="20%" padding="5px" key={props.name}>
              <Label>{props.name}</Label>
              <Icon name={props.name} colorizeStroke={props.colorizeStroke} />
            </Box>
          ))}
        </Flex>
      </Section>

      <Section>
        <StyleguideSectionTitle>Section Intro</StyleguideSectionTitle>
        <SectionIntro
          title="Section Intro"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        />
      </Section>

      <Section>
        <StyleguideSectionTitle>Contact Area</StyleguideSectionTitle>

        <Contact />
      </Section>

      <Section>
        <StyleguideSectionTitle>Service Grid</StyleguideSectionTitle>

        <ServiceGrid />
      </Section>

      <Section>
        <StyleguideSectionTitle>Service Information</StyleguideSectionTitle>

        <ServiceInformation
          icon="datavis"
          title="Data Visualization"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Qasdeuis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          skills={[
            'Develop data driven tools for your needs',
            'Realize your project idea',
            'Extend your journalistic piece with data visualization',
            'Develop data management tools',
          ]}
          projects={[
            {
              client: 'Zeit Online',
              label: 'Brexit Through British Tabloids',
              href: 'https://www.zeit.de/politik/ausland/2020-01/brexit-britische-tabloids-grossbritannien-zeitungen-titelbilder',
            },
            {
              client: 'Berliner Morgenpost',
              label: 'Berlin at your Line',
              href: 'https://interaktiv.morgenpost.de/berlin-an-deiner-linie/',
            },
          ]}
        />
      </Section>

      <Section>
        <StyleguideSectionTitle>Clients</StyleguideSectionTitle>
        <ClientGrid />
      </Section>
      <Section>
        <StyleguideSectionTitle>Techno Stack</StyleguideSectionTitle>
        <TechnologyStack items={['React.js', 'MapboxGL', 'jQuery']} />
      </Section>
      <Section>
        <StyleguideSectionTitle>Project Intro</StyleguideSectionTitle>

        <ProjectIntro
          title="The new colors of Europe"
          description="How 80.000 people have voted in the european election 2019"
          client="Zeit Online"
          tasks={['Programming', 'Engeneering']}
          team={['Julius Tröger', 'Julian Stahnke']}
          published="25.07.2020"
          links={[
            { text: 'Project Page', href: 'https://webkid.io' },
            { text: 'Github', href: 'https://github.com' },
          ]}
        />
      </Section>
      <Section>
        <StyleguideSectionTitle>Code Block</StyleguideSectionTitle>

        <CodeBlock
          title="A JS Code Block"
          subtitle="This is a subtitle"
          language="javascript"
          code={`import React from 'react';
import ReactFlow from 'react-flow-renderer';

const elements = [
  { id: '1', data: { label: 'Node 1' }, position: { x: 250, y: 5 } },
  { id: '2', data: { label: 'Node 2' }, position: { x: 100, y: 100 } },
  { id: 'e1-2', source: '1', target: '2', animated: true }
];

const graphStyles = { width: '100%', height: '500px' };

const BasicGraph = () => <ReactFlow elements={elements} style={graphStyles} />;`}
        />
      </Section>
    </Page>
  );
};

export default Styleguide;
